<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Lampiran 1G</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" id="card-custom">
            <template v-if="!isUserRole">
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units"
                  v-model="unit"
                  :loading="loadingUnit"
                  placeholder="Unit Utama"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_utama_id"
                  clearable
                  @change="changeUnit(1)"
                  @click:clear="changeUnit(1)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units2"
                  v-model="unit2"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_2_id"
                  clearable
                  @change="changeUnit(2)"
                  @click:clear="changeUnit(2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units3"
                  v-model="unit3"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_3_id"
                  clearable
                  @change="changeUnit(3)"
                  @click:clear="changeUnit(3)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units4"
                  v-model="unit4"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_4_id"
                  clearable
                ></v-autocomplete>
              </v-col>
            </template>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    placeholder="Tanggal Mulai"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  type="month"
                  locale="id"
                  no-title
                  scrollable
                  @input="menuStart = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    placeholder="Tanggal Akhir"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateEnd"
                  type="month"
                  locale="id"
                  no-title
                  scrollable
                  @input="menuEnd = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-btn color="#FBB005" class="px-10" @click="getLampiran1G" block>
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions v-if="summaries && summaries.length > 0">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="mx-2 px-5 text-capitalize"
              :loading="loadingExport"
              @click="exportLampiran1G"
              >Export Excel</v-btn
            >
          </v-card-actions>
          <v-data-table
            id="table-custom"
            class="mt-2"
            multi-sort
            :headers="headers"
            :items="summaries"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            hide-default-header
          >
            <template #header>
              <thead>
                <tr id="border-btm">
                  <th rowspan="2" class="text-center">NO</th>
                  <th rowspan="2" class="text-center">BULAN</th>
                  <th rowspan="2" class="text-center">NIP</th>
                  <th rowspan="2" class="text-center">NAMA</th>
                  <th rowspan="2" class="text-center">JABATAN</th>
                  <th rowspan="2" class="text-center">TUNJANGAN KINERJA</th>
                  <th rowspan="1" colspan="2" class="text-center">
                    Persentase Komulatif Komponen Capaian Kinerja
                  </th>
                  <th rowspan="2" class="text-center">
                    Persentase Komulatif Komponent Kehadiran
                  </th>
                  <th rowspan="2" class="text-center">
                    Tunjangan Kinerja yang Diterima Berdasarkan Nilai Capaian
                    Kinerja
                  </th>
                  <th rowspan="2" class="text-center">
                    Tunjangan Kinerja yang Diterima Berdasarkan Komponen
                    Kehadiran
                  </th>
                  <th rowspan="2" class="text-center">Jumlah Potongan</th>
                  <th rowspan="2" class="text-center">
                    Tunjangan Kinerja yang Diterima Per Bulan
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Nilai Capaian Kinerja</th>
                  <th class="text-center">Pengkonstanan</th>
                </tr>
              </thead>
            </template>
            <template v-slot:[`item.period`]="{ item }">
              {{ item.monthPeriod }} {{ item.yearPeriod }}
            </template>
            <template v-slot:[`item.income_get`]="{ item }">
              Rp. {{ item.income_get | currency }}
            </template>
            <template v-slot:[`item.income_attendance`]="{ item }">
              Rp. {{ item.income_attendance | currency }}
            </template>
            <template v-slot:[`item.pinalty_sum`]="{ item }">
              {{ item.pinalty_sum }} %
            </template>
            <template v-slot:[`item.income_performance`]="{ item }">
              Rp. {{ item.income_performance | currency }}
            </template>
            <template v-slot:[`item.pinalty_amount`]="{ item }">
              Rp. {{ item.pinalty_amount | currency }}
            </template>
            <template v-slot:[`item.income_sum`]="{ item }">
              Rp. {{ item.income_sum | currency }}
            </template>
            <template v-slot:no-data>
              <ContentNotFound message="Lampiran 1G tidak ada" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";
import ReportService from "@/services/resources/report.service";
const ContentNotFound = () => import("@/components/Content/NotFound");
import { mapGetters } from "vuex";
import { mapAuthRootField } from "@/store/helpers";

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      loadingUnit: false,
      loadingExport: false,
      dateStart: new Date().toISOString().substr(0, 7),
      menuStart: false,
      dateEnd: new Date().toISOString().substr(0, 7),
      menuEnd: false,
      search: null,
      unit: null,
      unit2: null,
      unit3: null,
      unit4: null,
      units: [],
      units2: [],
      units3: [],
      units4: [],
      headers: [
        { text: "NOMOR", value: "no", sortable: false, align: "center" },
        { text: "BULAN", value: "period", sortable: false, align: "center" },
        { text: "NIP", value: "NIP", sortable: false, align: "center" },
        { text: "NAMA", value: "nama", sortable: false, align: "center" },
        {
          text: "JABATAN",
          value: "nama_jabatan",
          sortable: false,
          align: "center"
        },
        {
          text: "TUNJANGAN KINERJA",
          value: "income_get",
          sortable: false,
          align: "center"
        },
        {
          text: "NILAI CAPAIAN KERJA",
          value: "capaian_bulanan",
          sortable: false,
          align: "center"
        },
        {
          text: "PENGKONSTANAN",
          value: "percent_bulanan",
          sortable: false,
          align: "center"
        },
        {
          text: "PERSENTASE KOMULATIF",
          value: "pinalty_sum",
          sortable: false,
          align: "center"
        },
        {
          text: "TUNJANGAN CAPAIAN KINERJA",
          value: "income_performance",
          sortable: false,
          align: "center"
        },
        {
          text: "TUNJANGAN KEHADIRAN",
          value: "income_attendance",
          sortable: false,
          align: "center"
        },
        {
          text: "JUMLAH POTONGAN",
          value: "pinalty_amount",
          sortable: false,
          align: "center"
        },
        {
          text: "JUMLAH DITERIMA",
          value: "income_sum",
          sortable: false,
          align: "center"
        }
      ],
      totalItem: 0,
      pageCount: 0,
      loading: false,
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      summaries: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isUserRole() {
      return this.currentUser.role === "USER";
    },
    ...mapAuthRootField({
      profile: "profile"
    })
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getLampiran1G);
      },
      deep: true
    }
  },
  methods: {
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    populateUnit() {
      this.units2 = this.units.find(d =>
        d.unit_utama_id.includes(this.unit)
      )?.unit_kerja2;
      this.units3 = this.units2.find(
        d => d.unit_kerja_2_id == this.unit2
      )?.unit_kerja3;
      this.units4 = this.units3.find(
        d => d.unit_kerja_3_id == this.unit3
      )?.unit_kerja4;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;

              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getLampiran1G() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await ReportService.lampiran1GList({
          export: false,
          period_start: this.dateStart
            ? this.$moment(this.dateStart).format("YYYYMM")
            : "",
          period_end: this.dateEnd
            ? this.$moment(this.dateEnd).format("YYYYMM")
            : "",
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let summaries = list;
              summaries.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                const year = data.period.substring(0, 4);
                const month = data.period.substring(5, 6);
                data.monthPeriod = this.$moment(month, "MM").format("MMMM");
                data.yearPeriod = year;
                return data;
              });

              this.summaries = summaries;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async exportLampiran1G() {
      this.loadingExport = true;
      try {
        this.loadingExport = true;
        await ReportService.lampiran1GList({
          export: true,
          period_start: this.dateStart
            ? this.$moment(this.dateStart).format("YYYYMM")
            : "",
          period_end: this.dateEnd
            ? this.$moment(this.dateEnd).format("YYYYMM")
            : "",
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const url = data.file;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", data.name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingExport = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    if (this.profile) {
      this.unit = this.profile.unit_utama_id;
      this.unit2 = this.profile.unit_kerja_2_id;
      this.unit3 = this.profile.unit_kerja_3_id;
      this.unit4 = this.profile.unit_kerja_4_id;
    }
    this.getListUnit();
    this.getLampiran1G();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #f5f6fa;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    color: #a3a6b4;
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
